import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError, from } from 'rxjs';
import { map, catchError, switchMap } from 'rxjs/operators';

import { HttpCancelService } from './httpcancel.service';
import { takeUntil } from 'rxjs/operators';

import { Preferences } from '@capacitor/preferences';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor(private httpCancelService: HttpCancelService) {}

  // Intercepts all HTTP requests!
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        this.httpCancelService.cancelPendingRequests();
        return from(Preferences.get({key:'authObject'}))
        .pipe(
            
            switchMap(ret => {
            const authObject = JSON.parse(ret.value);
            const token = authObject === null ? null : authObject.AuthToken;
            const clonedReq = this.addToken(request, token);
            return next.handle(clonedReq).pipe(
                        takeUntil(this.httpCancelService.onCancelPendingRequests()),
                        map((event: HttpEvent<any>) => {
                            return event;
                        }),
                        catchError((error: HttpErrorResponse) => {
                            return throwError(error);
                        })
                    );
            })
        );
    }

    // Adds the token to your headers if it exists
    private addToken(request: HttpRequest<any>, token: any) {
        if (token && request.url.indexOf("/my_token")===-1) {
            let clone: HttpRequest<any>;
            clone = request.clone({
                setHeaders: {
                Accept: 'application/json', 'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token
                }
            });
            return clone;
        }
        return request;
    }
}
