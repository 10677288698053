import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';

import { SERVER_URL } from '../../environments/environment';
import { AuthObject, UserProfile, Mall, ExternalAuthDetails} from '../classes/profile';
import { AlertController  } from '@ionic/angular';

import { Preferences } from '@capacitor/preferences';
import { ProfileService } from '../services/profile.service';
import { ResponseData } from '../classes/store';

import * as CryptoJS from 'crypto-js';
import { Capacitor } from '@capacitor/core';
@Injectable({
  providedIn: 'root'
})

export class AuthService  {
  public readonly apiURL: string = SERVER_URL;

  public isGooglePlusLogin = false;
  public isFacebookLogin = false;

  public exAuthToken = '';
  public exAuthProvider = '';
  public isFacebookEmail = false;

  constructor(
    private httpClient: HttpClient,
    private alertCtrl:AlertController,
    private profileService: ProfileService) {}

    private encrypt(msg:string){
      var keyWords = CryptoJS.enc.Utf8.parse('V1RM3GAK');
      var ivWords = CryptoJS.enc.Utf8.parse('V1RM3GAV');
      return CryptoJS.DES.encrypt(msg, keyWords, { iv: ivWords}).toString();
    }

  async login(userName: string, password: string,isGuestLogin:boolean, callback:any) {
    this.logout();
    const params = new URLSearchParams();
    params.set('grant_type', 'password');
    params.set('username', userName);
    params.set('password', this.encrypt(password));

    params.set('hashkey', this.encrypt(new Date().toISOString()));

    const httpHeaders = new HttpHeaders()
    .delete('Authorization')
    .set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');

    this.httpClient.post(this.apiURL + '/myvir_token', params.toString(), {headers: httpHeaders})
          .pipe(
            map(loginResponse => {
              if (loginResponse) {
                const loginResponseJSON = JSON.parse(JSON.stringify(loginResponse));
                const authObject: AuthObject = new AuthObject();
                authObject.AuthToken = loginResponseJSON.access_token;
                authObject.Profile = new UserProfile({
                  ProfileId: loginResponseJSON.profileId
                });

                authObject.Mall= new Mall({
                  MallId: loginResponseJSON.MallId,
                  MallName: loginResponseJSON.MallName,
                  CashBack: loginResponseJSON.cashback, 
                  CbText: loginResponseJSON.CbText,
                  LogoURL: loginResponseJSON.LogoURL,
                  MinimumEligibleAmount: loginResponseJSON.MinimumEligibleAmount,
                  PortalType: loginResponseJSON.PortalType,
                  Profile2MallId: loginResponseJSON.profile2mallid,
                  SignupBonus: loginResponseJSON.SignupBonus
                });

                authObject.RefreshToken = loginResponseJSON.refresh_token;
                authObject.TokenExpireIn = loginResponseJSON.expires_in;
                authObject.IsGuestLogin=isGuestLogin;
                return authObject;
              } else {
                throw new Error('Not a valid login resposne');
              }
            })
        ).subscribe({
          next: async (authObj:AuthObject)=>{
            await Preferences.set({
              key: 'authObject',
              value: JSON.stringify(authObj)
            }).then(()=>{
              callback("success");
            });
          },
          error: async error=>{
            callback("err");
            let msg = 'Unknown error';
            if (error instanceof HttpErrorResponse) {
              if ((error as HttpErrorResponse).error.error_description !== undefined) {
                msg = (error as HttpErrorResponse).error.error_description;
                
              } else {
                msg = (error as HttpErrorResponse).message;
              }
            }

            if(msg.split("|").length>1)
              msg=msg.split("|")[1].trim()
            
            await this.handleError(msg);
          }
        });
  }
  
  loginWithExternalToken(exAuthDetails: ExternalAuthDetails, deviceOsName: string, thisAppVersion: string, callback:any){
    this.logout();
    const params = new URLSearchParams();
    params.set('grant_type', 'password');
    params.set('username', '');
    params.set('password', '');

    params.set('hashkey', this.encrypt(new Date().toISOString()));
    
    params.set('deviceOsName', deviceOsName);
    params.set('thisAppVersion', thisAppVersion);

    params.set('mallid', exAuthDetails.MallId);
    params.set('parentRefCode', exAuthDetails.ParentReferralCode);
    
    params.set('exToken', exAuthDetails.ExAuthToken);
    params.set('provider', exAuthDetails.ExProvider);

    if(exAuthDetails.ExProvider=='apple'){
      params.set('familyName', exAuthDetails.FamilyName);
      params.set('givenName', exAuthDetails.GivenName);
      params.set('userId', exAuthDetails.UserId);
      params.set('exUserName', exAuthDetails.ExUserName);
    }
    if (exAuthDetails.CustomEmail !== undefined) {
        params.set('customemail', exAuthDetails.CustomEmail);
    }

    // for signup
    params.set('createcaptcha', exAuthDetails.CreateCaptcha ? '1' : '0');
    params.set('canemail', exAuthDetails.CanEmail ? '1' : '0');

    params.set('isPWA', Capacitor.isNativePlatform() ? '0' : '1');

    const httpHeaders = new HttpHeaders()
    .delete('Authorization')
    .set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
    this.httpClient.post(this.apiURL + '/myvir_token', params.toString(), {headers: httpHeaders})
      .pipe(
        map(loginResponse => {
          if (loginResponse) {
            const loginResponseJSON = JSON.parse(JSON.stringify(loginResponse));
            const authObject: AuthObject = new AuthObject();
            authObject.AuthToken = loginResponseJSON.access_token;
            authObject.Profile = new UserProfile({
              ProfileId: loginResponseJSON.profileId
            });

            authObject.Mall= new Mall({
              MallId: loginResponseJSON.MallId,
              MallName: loginResponseJSON.MallName,
              CashBack: loginResponseJSON.cashback, 
              CbText: loginResponseJSON.CbText,
              LogoURL: loginResponseJSON.LogoURL,
              MinimumEligibleAmount: loginResponseJSON.MinimumEligibleAmount,
              PortalType: loginResponseJSON.PortalType,
              Profile2MallId: loginResponseJSON.profile2mallid,
              SignupBonus: loginResponseJSON.SignupBonus
            });
            authObject.RefreshToken = loginResponseJSON.refresh_token;
            authObject.TokenExpireIn = loginResponseJSON.expires_in;
            authObject.IsGuestLogin=false;
            return authObject;
          } else {
            throw new Error('Not a valid login resposne');
          }
        })
      ).subscribe({
        next: async (authObj:AuthObject)=>{
          await Preferences.set({
            key: 'authObject',
            value: JSON.stringify(authObj)
          }).then(()=>{
            callback("success");
          });
        },
        error: async error=>{
          let msg = 'Unknown error';
          if (error instanceof HttpErrorResponse) {
            if ((error as HttpErrorResponse).error.error_description !== undefined) {
              msg = (error as HttpErrorResponse).error.error_description;
            } else {
              msg = (error as HttpErrorResponse).message;
            }
          }
          if(msg.indexOf("HTTP Error 204")>-1){
            callback("exSignup");
          }else if(msg.indexOf("HTTP Error 206")>-1){
            callback("exSignupWithEmail");
          }else{
            callback("err");
            await this.handleError(msg);
          }
        }
      });
  }
 
  // Logout a user, destroy token and remove
  // every information related to a user
  private logout(): Promise<boolean> {
    return new Promise(async (resolve) => {
      await Preferences.remove({key:'authObject'}).then(()=>{
        resolve(true);
      });
    });
  }
  
  public RegisterWithEmail(
    mallId: string,
    parentRefCode: string,
    firstname: string,
    lastname: string,
    username: string,
    password: string,
    canEmail: boolean,
    deviceOsName: string,
    thisAppVersion: string,
    callback: any){

    const data = {
      mallId,
      parentRefCode,
      firstname,
      lastname,
      username,
      password,
      canEmail,
      deviceOsName: deviceOsName,
      appVersion: thisAppVersion
    };

    const httpHeaders = new HttpHeaders()
    .delete('Authorization')
    .set('Content-Type', 'application/json');

    this.httpClient.post(SERVER_URL + '/Profile/Register', JSON.stringify(data), {headers: httpHeaders})
    .pipe(
        map(response => {
            const responseJSON = JSON.parse(JSON.stringify(response));

            if (responseJSON.ResponseData.Type === 'Success') {
              return true;
            } else {
              throw new Error(responseJSON.ResponseData.Data.Message);
            }
          })
    ).subscribe({
      next: async (result:boolean)=>{
        this.login(username,password,false,()=>{
          callback("success");
        });
      },
      error: async (error: Error)=>{
        callback("err");
        await this.handleError(error.message);
      }
    });
  }


  public recoverPassword(username: string, callback: any){
    let Params = new HttpParams();
    Params = Params.append('username', username);

    return this.httpClient
      .get(SERVER_URL + '/Profile/recoverpassword', {params: Params})
      .pipe(
        map(response => {
          const responseJSON = JSON.parse(JSON.stringify(response));
          if (responseJSON.ResponseData.Type === 'Success') {
            return responseJSON.ResponseData.Data;
          } else {
            throw new Error(responseJSON.ResponseData.Data.Message);
          }
        })
      ).subscribe({
        next: async (result:string)=>{
          callback("success");
          const alert = await this.alertCtrl.create({
            header: '',
            message: result,
            buttons: ['OK']
          });
          await alert.present();
        },
        error: async (error: Error)=>{
          callback("err");
          await this.handleError(error.message);
        }
      });
  }
  
  async handleError(_msg: string) {
    var msg:string=_msg;
    var username:string;
    let alrtBtns=[{
      text:'Ok',
      handler: ()=>{}
    }]
    if(_msg.indexOf('disabled')>0){
      
      var s=_msg.substr(_msg.indexOf("'")+1)
      username=_msg.substr(_msg.indexOf("'")+1,s.indexOf("'"));

      msg='Your account '+username+' is deactivated for no activity.<br/> Please click the Activate button below to enable your account'
      
      alrtBtns=[{
          text:"Activate",
          handler:()=>{
            this.profileService.ActivateUser(username).subscribe({
              next: async (value:ResponseData)=>{
                const alert2 = await this.alertCtrl.create({
                  header: '',
                  message: value.Data+'<br/> Please login again',
                  buttons: ['Ok']
                });
                await alert2.present();
              }
            })
          }
        },
        {
          text:"Cancel",
          handler:()=>{}
        }
      ]
    }

    const alert = await this.alertCtrl.create({
      header: '',
      message: msg,
      buttons: alrtBtns
    });
    await alert.present();
  }

}

