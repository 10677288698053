import { AuthObject, Mall } from "./profile";

export class ResponseData {
    PageNumber!: number;
    PageSize!: number;
    TotalCount!: number;
    Data!: any;
    AuthObj!:AuthObject;
    constructor(values: object= {}) {
      Object.assign(this, values);
    }
  }

export class Category {
    Id!: number;
    MerchantsCount!: number;
    Name!: string;
    ParentId!: number;
    ChildCategories!: Category[];
    open!: boolean;
    constructor(values: object= {}) {
      Object.assign(this, values);
    }

    Title(): string {
      if (this.ChildCategories !== undefined && this.ChildCategories.length > 0) {
        return this.Name + ' (' + this.ChildCategories.length.toString() + ')';
      } else {
        return this.Name + ' (' + this.MerchantsCount.toString() + ')';
      }
    }
  }

export class Store {
    Id!: number;
    Name!: string;
    CashBackText!: string;
    CashBackTextOnly!: string;
    Description!: string;
    Logo!: string;
    TotalBestDealCount!: number;
    TotalOfferCount!: number;
    Deals!: Deal[];
    isFavorite!:boolean;
    constructor(values: object= {}) {
      Object.assign(this, values);
    }

    Title(isBestDeal: boolean): string {
      if (!isBestDeal) {
        return this.Name + ' (' + this.TotalOfferCount.toString() + ')';
      } else {
        return this.Name + ' (' + this.TotalBestDealCount.toString() + ')';
      }
    }
  }

export class Deal {
    Id!: number;
    Description!: string;
    ImageSrc!: string;
    ExpiryDate!: string;
    Merchant!: Store;
    IsFreeShipping! : boolean;
    CouponCode! : string;
    constructor(values: object= {}) {
      Object.assign(this, values);
    }
  }


export class SearchResult {
    Brands!: string[];
    Merchants!: Merchant[];
    MaxPageSize!: number;
    MaxRequests!: number;
    MerchantsCount!: number;
    TotalCount!: number;
    TotalRequests!: number;
    DataList!: Product[];
    constructor(values: object= {}) {
      Object.assign(this, values);
    }
  }

export class Product {
    Id!: string;
    Name!: string;
    Description!: string;
    ImageURL!: string;
    UniqueEAN!: string;
    UniqueUPC!: string;
    Merchants!: Merchant[];
    constructor(values: object= {}) {
      Object.assign(this, values);
    }
  }

export class Merchant {
    Id!: number;
    ApiId!: number;
    ApiName!: string;
    LogoUrl!: string;
    CashBackText!: string;
    AffiliateUrl!: string;
    ClickCode!: string;
    Name!: string;
    SourceName!: string;
    Title!: string;
    TotalProducts!: number;
    FinalPrice!: number;
    Checked!: boolean;
    constructor(values: object= {}) {
      Object.assign(this, values);
    }
  }

  export enum  OfferTypes
  {
      Undefined = 0,
      HotDeal = 1,
      Coupons = 2,
      FreeShipping = 3,
      Seasonal = 4,
      TopDeal = 5,

      //No idea why these were separated out, but that's how it was done.  We'll fix it later.
      TopPick = 6,
      BestPick = 7,

      //General offers ... clicks on a merchant store
      General = 250
  }

  export class HomeModel {    
    BestDeals!: Deal[];
    HotDeals!: Deal[];
    FeaturedStores!: Deal[];
    PromoCodes!: Deal[];
    LatestHDPCCampaign! : CampaignOffers;
    LatestBDealsCampaign! : CampaignOffers;
    constructor(values: object= {}) {
      Object.assign(this, values);
    }
  }

  export class CampaignOffers{
    CampaignTitle! : string;
    Offers! : Deal[];
  }

