import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuardService } from './services/auth-guard.service';
import { NotFoundComponent } from './not-found/not-found.component';

const routes: Routes = [
  { path: '', loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule), canActivate: [AuthGuardService] },
  { path: 'account-details', loadChildren: () => import('./tabs/pages/account/account-details/account-details.module').then(m => m.AccountDetailsPageModule) },
  { path: 'click-history', loadChildren: () => import('./tabs/pages/account/click-history/click-history.module').then(m => m.ClickHistoryPageModule) },
  { path: 'transaction-history', loadChildren: () => import('./tabs/pages/account/transaction-history/transaction-history.module').then(m => m.TransactionHistoryPageModule) },
  { path: 'request-payment', loadChildren: () => import('./tabs/pages/account/request-payment/request-payment.module').then(m => m.RequestPaymentPageModule) },
  { path: 'howitworks', loadChildren: () => import('./tabs/pages/account/howitworks/howitworks.module').then(m => m.HowitworksPageModule) },
  { path: 'support', loadChildren: () => import('./tabs/pages/account/support/support.module').then(m => m.SupportPageModule) },
  { path: 'eula', loadChildren: () => import('./tabs/pages/account/eula/eula.module').then(m => m.EulaPageModule) },
  { path: 'change-password', loadChildren: () => import('./tabs/pages/account/change-password/change-password.module').then(m => m.ChangePasswordPageModule) },
  { path: 'faq', loadChildren: () => import('./tabs/pages/account/faq/faq.module').then(m => m.FaqPageModule) },
  { path: 'invite-friends', loadChildren: () => import('./tabs/pages/invite-friends/invite-friends.module').then(m => m.InviteFriendsPageModule) },
  //{ path: 'paymentrequest', loadChildren: './tabs/pages/account/paymentrequest/paymentrequest.module#PaymentrequestPageModule' },
  { path: 'details', loadChildren: () => import('./tabs/pages/details/details.module').then( m => m.DetailsPageModule) },
  { path: 'disclosure', loadChildren: () => import('./tabs/pages/account/disclosure/disclosure.module').then( m => m.DisclosurePageModule) },
  { path: 'cashbackdetail', loadChildren: () => import('./tabs/pages/account/cashbackdetail/cashbackdetail.module').then( m => m.CashbackdetailPageModule) },
  { path: 'about', loadChildren: () => import('./tabs/pages/account/about/about.module').then( m => m.AboutPageModule)},
  { path: 'referral-club', loadChildren: () => import('./tabs/pages/referral-club/referral-club.module').then( m => m.ReferralClubPageModule) },
  { path: 'clubearning-history', loadChildren: () => import('./tabs/pages/account/club-earning-history/club-earning-history.module').then(m => m.ClubEarningHistoryPageModule) },
  { path: 'agreement', loadChildren: () => import('./tabs/pages/account/agreement/agreement.module').then( m => m.AgreementPageModule) },
  { path: 'privacy', loadChildren: () => import('./tabs/pages/account/privacy/privacy.module').then( m => m.PrivacyPageModule)},
  { path: 'help/appprivacy', loadChildren: () => import('./tabs/pages/account/privacy/privacy.module').then( m => m.PrivacyPageModule)},
  { path: 'help/privacy', loadChildren: () => import('./tabs/pages/account/privacy/privacy.module').then( m => m.PrivacyPageModule)},
  { path: 'help/customersupport', loadChildren: () => import('./tabs/pages/account/support/support.module').then(m => m.SupportPageModule) },
  { path: '**',component:NotFoundComponent},
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
