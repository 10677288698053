export class AuthObject {
    AuthToken: string;
    TokenExpireIn: number;
    RefreshToken: string;
    Profile: UserProfile;
    Mall: Mall;
    ExAuthToken: string;
    ExProvider: string;
    IsGuestLogin: boolean;
  }

export class CashbackDetail{
  BalanceAmount: number;
  AvailableAmount: number;
  PendingAmount: number;
  PaidAmount: number;
  MonthlyCharges: number;
  RequestedAmount: number;

  constructor(values: object= {}) {
    Object.assign(this, values);
  }

}

export class UserProfile {
    ProfileId: string;
    Profile2Mallid: number;
    availableAmount: string;
    birthDate: string;
    email: string;
    firstName: string;
    lastName: string;
    gender: string;
    country: number;
    state: number;
    stateName: string;
    city: number;
    cityName: string;
    phone: string;
    fax: string;
    zip: string;
    billFirstName: string;
    billLastName: string;
    streetAddress1: string;
    streetAddress2: string;
    referralCode: string;
    registrationDate: string;
    paidAmount: string;
    pendingAmount: string;
    totalAmount: string;
    canEmail: boolean;
    canNotify: boolean;
    securityQuestion: string;
    mallId: string;
    mallName: string;
    totalChildUserCount: number;
    
    constructor(values: object= {}) {
      Object.assign(this, values);
    }

    FullName(): string {
      return this.firstName + ' ' + this.lastName;
    }
  }

export class Mall {
    MallId: string;
    MallName: string;
    IsOnline: boolean;
    CashBack: number;
    CbText: string;
    LogoURL: string;
    MinimumEligibleAmount: number;
    PortalType: string;
    Profile2MallId: number;
    SignupBonus: number;

    constructor(values: object= {}) {
      Object.assign(this, values);
    }
  }

export class ExternalAuthDetails {
    ExProvider: string;
    ExUserName: string;
    ExAuthToken: string;
    ParentReferralCode: string;
    MallId: string;
    CanEmail: boolean;
    CustomEmail: string;
    CreateCaptcha: boolean;

    FamilyName: string;
    GivenName: string;
    UserId: string;
  }

export class FBAuthResult {
    AuthToken: string;
  }

export class ClickHistory {
  ClickId: number;
  ClickTime: Date;
  MerchantName: string;
  Reference: string;
  constructor(values: object= {}) {
    Object.assign(this, values);
  }
}

export enum EmailType {
  Undefined = 0,
  Registration = 1,
  ContactUs = 2,
  LearnSeriesNewsLetter = 3,
  Notification = 4,
  ReferAFriend = 5,
  PaymentRequestSuccess = 6,
  Transaction = 7,
  ForgetPassword = 8,
  AdminRegistration = 9,
  Error = 10,
  LearnSeriesSpecialLetter = 11,
  RegistrationVerification = 12,
  PaymentRequestVerification = 13
}

export class Message {
  MessageId: string;
  SendingDomain: string;
  SendFrom: string;
  SendTo: string;
  Subject: string;
  Body: string;
  ScheduleDate: Date;
  MessageType: EmailType;
  constructor(values: object= {}) {
    Object.assign(this, values);
  }
}

export enum TransactionPaymentStatus
{
    NotPaid = -1,
    Pending = 0,
    Available = 1,
}

export class Transaction {
  TransactionId: string;
  ProcessDate: Date;
  PurchaseDate: Date;
  MerchantName: string;
  AccountName: string;
  VendorOrderCode: string;
  VendorOrderSKU: string;
  Source: string;
  EClickSource: string;
  PurchaseAmount: number;
  GrossCommissionAmount: number;
  LockedDate: Date;
  constructor(values: object= {}) {
    Object.assign(this, values);
  }
}

export class Country {
  Id: number;
  Name: string;
  Alpha2Code: string;
  Alpha3Code: string;
  NumericCode: string;
  MerchantCount: number;
  constructor(values: object= {}) {
    Object.assign(this, values);
  }
}

export class CountryState {
  Id: number;
  CountryId: number;
  Name: string;
  Code: string;
  MerchantCount: number;
  constructor(values: object= {}) {
    Object.assign(this, values);
  }
}

export class CountryCity {
  Id: number;
  Name: string;
  CountryId: number;
  StateId: number;
  MerchantCount: number;
  constructor(values: object= {}) {
    Object.assign(this, values);
  }
}

export class Configuration{
    MallId:string="";
    MallName:string="";
    ParentRefCode:string="";
    MallURL:string="";
    GuestPasswordHash:string="";
    SiteTitle:string="";
    AppName: string="";
    ApplicationId: string="";
    constructor(values: object= {}) {
      Object.assign(this, values);
    }
}
