import { Component, OnInit } from '@angular/core';
import { Router, NavigationExtras } from '@angular/router';
import { ProfileService } from '../services/profile.service';
import { CommonService } from '../services/common.service';
import { Configuration } from '../classes/profile';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss'],
})
export class NotFoundComponent  implements OnInit {

  constructor(
    private router: Router,
	private commonService: CommonService,
    private profileService:ProfileService,) { }

  async ngOnInit() {
    var referralCode=this.router.url.replace('/','');
    this.profileService.getCurrentReferralValidate(referralCode, true).then(async (result)=>{
	  if(result.Data.IsReferralCodeExists){
		this.commonService.getAppConfiguration().then(async (conf:Configuration)=>{
			conf.ParentRefCode=referralCode;
			await this.commonService.updateParentRefCode(conf);
		})
	  }
	  await this.router.navigate(['tabs/home']);
    });
  }

}
