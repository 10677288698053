import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { forkJoin, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CommonService } from './common.service';
import { SERVER_URL } from '../../environments/environment';

import { AuthObject, UserProfile, CashbackDetail } from '../classes/profile';
import { ResponseData } from '../classes/store';
import { CachingService } from './caching.service';
@Injectable({
  providedIn: 'root'
})

export class ProfileService {

  constructor(
    private httpClient: HttpClient,
    private commonService: CommonService,
    private cachingService: CachingService) { }

    public getCashbackDetails(resetCache:boolean): Promise<CashbackDetail> {
      return this.commonService.isAuthenticated().then((authObj:AuthObject)=>{
        if(!authObj.IsGuestLogin){
  
        let Params = new HttpParams();
        Params = Params.append('mallId', authObj.Mall.MallId);
        Params = Params.append('profileId', authObj.Profile.ProfileId);
        Params = Params.append('resetCache', resetCache);
  
        var cacheKey = `CashbackDetails_${authObj.Mall.MallId}_${authObj.Profile.ProfileId}`;

        if(resetCache){
          this.cachingService.invalidateCacheEntry(cacheKey);
        }

        return this.cachingService.getCachedData(cacheKey).then((data)=>{
          if(data!=null){
            
            return data;
          }else{
            return this.httpClient
            .get(SERVER_URL + '/Profile/CashbackDetails', {params: Params})
            .toPromise()
            .then(response => {
              const responseJSON = JSON.parse(JSON.stringify(response));
              if (responseJSON.ResponseData.Type === 'Success') {
                this.cachingService.cacheData(cacheKey,responseJSON.ResponseData.Data);
                
                return new CashbackDetail(responseJSON.ResponseData.Data);
              } else {
                throw new Error(responseJSON.ResponseData.Data.Message);
              }
            });
          }
        });
        }
      });
  }

  public getProfile(): Promise<UserProfile> {
    return this.commonService.isAuthenticated().then((authObj:AuthObject)=>{
      return this.getProfileForId(authObj, authObj.Profile.ProfileId);
    });
  }

  public getProfileForId(authObj:AuthObject, profileId:string): Promise<UserProfile> {
    let Params = new HttpParams();
    Params = Params.append('mallId', authObj.Mall.MallId);
    Params = Params.append('profileId', profileId);

    var cacheKey = `Profile_${authObj.Mall.MallId}_${profileId}`;

    return this.cachingService.getCachedData(cacheKey).then((data)=>{
      if(data!=null){
        return data;
      }else{
        return this.httpClient
          .get(SERVER_URL + '/Profile/Index', {params: Params})
          .toPromise()
          .then(response => {
            const responseJSON = JSON.parse(JSON.stringify(response));
            if (responseJSON.ResponseData.Type === 'Success') {
              var p=new UserProfile(responseJSON.ResponseData.Data);
              p.mallId=authObj.Mall.MallId;
              p.mallName=authObj.Mall.MallName;

              this.cachingService.cacheData(cacheKey,p);
              return p;
            } else {
              throw new Error(responseJSON.ResponseData.Data.Message);
            }
        });
      }
    });
  }

  
  public getClickHistory(pageNumber: number, pageSize: number, refreshCache: boolean): Promise<ResponseData> {
    return this.commonService.isAuthenticated().then((authObj:AuthObject)=>{
      let Params = new HttpParams();
      Params = Params.append('mallId', authObj.Mall.MallId);
      Params = Params.append('profileId', authObj.Profile.ProfileId);
      Params = Params.append('pageNumber', pageNumber.toString());
      Params = Params.append('pageSize', pageSize.toString());
      Params = Params.append('refreshCache', refreshCache.toString());

      var cacheKey = `ClickHistory_${authObj.Mall.MallId}_${authObj.Profile.ProfileId}_${pageNumber}_${pageSize}`;

      if(refreshCache){
        this.cachingService.invalidateCacheEntry(cacheKey);
      }

      return this.cachingService.getCachedData(cacheKey).then((data)=>{
        if(data!=null){
          
          return data;
        }else{
          return this.httpClient
          .get(SERVER_URL + '/Profile/ClickHistory', {params: Params})
          .toPromise()
          .then(response => {
                const responseJSON = JSON.parse(JSON.stringify(response));
                if (responseJSON.ResponseData.Type === 'Success') {
                  this.cachingService.cacheData(cacheKey,responseJSON.ResponseData);
                  
                  return new ResponseData(responseJSON.ResponseData);
                } else {
                  throw new Error(responseJSON.ResponseData.Data.Message);
                }
          });
        }
      });
    });
  }

  
  public getTranDetails(pageNumber: number, pageSize: number, refreshCache: boolean, clubEarning:boolean): Promise<ResponseData> {
    return this.commonService.isAuthenticated().then((authObj:AuthObject)=>{
      let Params = new HttpParams();
      Params = Params.append('mallId', authObj.Mall.MallId);
      Params = Params.append('profileId', authObj.Profile.ProfileId);
      Params = Params.append('pageNumber', pageNumber.toString());
      Params = Params.append('pageSize', pageSize.toString());
      Params = Params.append('refreshCache', refreshCache.toString());
      Params = Params.append('clubEarning', clubEarning.toString());

      var cacheKey = `TranDetails_${authObj.Mall.MallId}_${authObj.Profile.ProfileId}_${pageNumber}_${pageSize}_${clubEarning}`;

      if(refreshCache){
        this.cachingService.invalidateCacheEntry(cacheKey);
      }

      return this.cachingService.getCachedData(cacheKey).then((data)=>{
        if(data!=null){
          
          return data;
        }else{
          return this.httpClient
          .get(SERVER_URL + '/Transactions/GetTranDetails', {params: Params})
          .toPromise()
          .then(response => {
                const responseJSON = JSON.parse(JSON.stringify(response));
                if (responseJSON.ResponseData.Type === 'Success') {
                  this.cachingService.cacheData(cacheKey,responseJSON.ResponseData);
                  
                  return new ResponseData(responseJSON.ResponseData);
                } else {
                  throw new Error(responseJSON.ResponseData.Data.Message);
                }
              });
        }
      })
    });
  }

  public getCountry(): Promise<ResponseData> {
    var cacheKey = "Countries";

    return this.cachingService.getCachedData(cacheKey).then((data)=>{
      if(data!=null){
        return data;
      }else{
        return this.httpClient
        .get(SERVER_URL + '/Transactions/GetCountries')
        .toPromise()
        .then(response => {
            const responseJSON = JSON.parse(JSON.stringify(response));
            if (responseJSON.ResponseData.Type === 'Success') {
                this.cachingService.cacheData(cacheKey,responseJSON.ResponseData);
                
                return new ResponseData(responseJSON.ResponseData);
              } else {
                throw new Error(responseJSON.ResponseData.Data.Message);
              }
          });
       }
      });
  }
  
  public getCountryState(countryId: number): Promise<ResponseData> {
    let Params = new HttpParams();
    Params = Params.append('countryId', countryId.toString());
    var cacheKey = `CountryState_${countryId}`;

    return this.cachingService.getCachedData(cacheKey).then((data)=>{
      if(data!=null){
        
        return data;
      }else{
        return this.httpClient
        .get(SERVER_URL + '/Transactions/GetCountryState', {params: Params})
        .toPromise()
        .then(response => {
            const responseJSON = JSON.parse(JSON.stringify(response));
            if (responseJSON.ResponseData.Type === 'Success') {
                this.cachingService.cacheData(cacheKey,responseJSON.ResponseData);
                
                return new ResponseData(responseJSON.ResponseData);
              } else {
                throw new Error(responseJSON.ResponseData.Data.Message);
              }
          });
      }
    });
  }

  public getCountryCity(countryId: number, stateid: number): Promise<ResponseData> {
    let Params = new HttpParams();
    Params = Params.append('countryId', countryId.toString());
    Params = Params.append('stateId', stateid.toString());
    var cacheKey = `CountryCities_${countryId}_${stateid}`;

    return this.cachingService.getCachedData(cacheKey).then((data)=>{
      if(data!=null){
        return data;
      }else{
        return this.httpClient
        .get(SERVER_URL + '/Transactions/GetCities', {params: Params})
        .toPromise()
        .then(response => {
            const responseJSON = JSON.parse(JSON.stringify(response));
            if (responseJSON.ResponseData.Type === 'Success') {
                this.cachingService.cacheData(cacheKey,responseJSON.ResponseData);
                
                return new ResponseData(responseJSON.ResponseData);
              } else {
                throw new Error(responseJSON.ResponseData.Data.Message);
              }
          });
      }
    });
  }

  public changePassWord(oldPassword: string, newPassword: string, newreferral: string, squestion: string, qanswer: string): Promise<Observable<boolean>> {
    return this.commonService.isAuthenticated().then((authObj:AuthObject)=>{
      let Params = new HttpParams();
      Params = Params.append('mallId', authObj.Mall.MallId);
      Params = Params.append('profileId', authObj.Profile.ProfileId);
      Params = Params.append('oldPassword', oldPassword.toString());
      Params = Params.append('newPassword', newPassword.toString());
      Params = Params.append('newreferral', newreferral.toString());
      Params = Params.append('squestion', squestion.toString());
      Params = Params.append('qanswer', qanswer.toString());

      return this.httpClient
      .get(SERVER_URL + '/Profile/ChangePassword', {params: Params})
      .pipe(
        map(response => {
          const responseJSON = JSON.parse(JSON.stringify(response));
          return responseJSON.ResponseData;
        })
      );
    });
  }

  public updatePersonalInfo(
    firstname: string, 
    lastname: string, 
    dob: string, 
    gender: string
    ): Promise<Observable<boolean>> {
    return this.commonService.isAuthenticated().then((authObj:AuthObject)=>{
      return this.httpClient.post(SERVER_URL + '/Profile/UpdateAccountInfo',
      {
        'mallId' : authObj.Mall.MallId,
        'profileId': authObj.Profile.ProfileId,
        'firstName': firstname,
        'lastName': lastname,
        'birthDate': dob,
        'gender': gender
      })
      .pipe(
        map(response => {
          var cacheKey = `Profile_${authObj.Mall.MallId}_${authObj.Profile.ProfileId}`;
          this.cachingService.invalidateCacheEntry(cacheKey);

          const responseJSON = JSON.parse(JSON.stringify(response));
          return responseJSON.ResponseData; 
        })
      );
    });
  }

  public updatePaymentInfo(    
    firstnameadd: string, 
    lastnameadd: string, 
    address1: string, 
    address2: string,
    country: string,
    state: string,
    city: string,
    zip: string,
    phone: string
    ): Promise<Observable<boolean>> {
    return this.commonService.isAuthenticated().then((authObj:AuthObject)=>{
      return this.httpClient.post(SERVER_URL + '/Profile/UpdateBillingInfo',
      {
        'mallId' : authObj.Mall.MallId,
        'profileId': authObj.Profile.ProfileId,        
        'firstnameadd': firstnameadd,
        'lastnameadd': lastnameadd,
        'address1': address1,
        'address2': address2,
        'country': country,
        'state': state,
        'city': city,
        'zip': zip,
        'phone': phone
      })
      .pipe(
        map(response => {
          var cacheKey = `Profile_${authObj.Mall.MallId}_${authObj.Profile.ProfileId}`;
          this.cachingService.invalidateCacheEntry(cacheKey);
          const responseJSON = JSON.parse(JSON.stringify(response));
          return responseJSON.ResponseData;        
        })
      );
    });
  }

  public updateEmailInfo(newEmail: string): Promise<Observable<boolean>> {
    return this.commonService.isAuthenticated().then((authObj:AuthObject)=>{
      return this.httpClient.post(SERVER_URL + '/Profile/UpdateEmail',
      {
        'mallId' : authObj.Mall.MallId,
        'profileId': authObj.Profile.ProfileId,        
        'newEmail': newEmail,
      })
      .pipe(
        map(response => {
          var cacheKey = `Profile_${authObj.Mall.MallId}_${authObj.Profile.ProfileId}`;
          this.cachingService.invalidateCacheEntry(cacheKey);
          const responseJSON = JSON.parse(JSON.stringify(response));
          return responseJSON.ResponseData;         
        })
      );
    });
  }

  public updateEmailSettingsInfo(    
    canEmail: boolean,
    canNotify: boolean
    ): Promise<Observable<boolean>> {
    return this.commonService.isAuthenticated().then((authObj:AuthObject)=>{
      return this.httpClient.post(SERVER_URL + '/Profile/UpdateEmailSettingsInfo',
      {
        'mallId' : authObj.Mall.MallId,
        'profileId': authObj.Profile.ProfileId,        
        'canEmail': canEmail,
        'canNotify': canNotify
      })
      .pipe(
        map(response => {
          var cacheKey = `Profile_${authObj.Mall.MallId}_${authObj.Profile.ProfileId}`;
          this.cachingService.invalidateCacheEntry(cacheKey);
          const responseJSON = JSON.parse(JSON.stringify(response));
          return responseJSON.ResponseData; 
        })
      );
    });
  }

  public customerSupport(senderName: string, 
    sendFrom: string, 
    msg: string, 
    deviceOsName: string, 
    appVersion: string): Promise<Observable<boolean>> {
      return this.commonService.isAuthenticated().then((authObj:AuthObject)=>{
        const data = {
          MallId: authObj.Mall.MallId,
          SenderName: senderName,
          SendFrom: sendFrom,
          Message: msg,
          ProfileId: authObj.Profile.ProfileId,
          deviceOsName: deviceOsName,
          appVersion: appVersion,
          isTran:false
        };

        return this.httpClient.post(SERVER_URL + '/Profile/CustomerSupport', JSON.stringify(data))
        .pipe(
          map(response => {
              const responseJSON = JSON.parse(JSON.stringify(response));
              if (responseJSON.ResponseData.Type === 'Success') {
                return true;
              } else {
                throw new Error(responseJSON.ResponseData.Data.Message);
              }
            })
        );
      });
  }


  public transactionSupport(
    orderDate:string,
    orderNo:string,
    orderAmount:number,
    storeName:string,
    senderName: string, 
    sendFrom: string, 
    msg: string, 
    deviceOsName: string, 
    appVersion: string): Promise<Observable<boolean>> {
      return this.commonService.isAuthenticated().then((authObj:AuthObject)=>{
        const data = {
          isTran:true,
          StoreName:storeName,
          OrderNo:orderNo,
          OrderAmount:orderAmount,
          OrderDate:orderDate,
          MallId: authObj.Mall.MallId,
          SenderName: senderName,
          SendFrom: sendFrom,
          Message: msg,
          ProfileId: authObj.Profile.ProfileId,
          deviceOsName: deviceOsName,
          appVersion: appVersion
        };

        return this.httpClient.post(SERVER_URL + '/Profile/CustomerSupport', JSON.stringify(data))
        .pipe(
          map(response => {
              const responseJSON = JSON.parse(JSON.stringify(response));
              if (responseJSON.ResponseData.Type === 'Success') {
                return true;
              } else {
                throw new Error(responseJSON.ResponseData.Data.Message);
              }
            })
        );
      });
  }

  public InviteFriend(
    msg: string): Promise<Observable<boolean>> {
      return this.commonService.isAuthenticated().then((authObj:AuthObject)=>{
        const data = {
          MallId: authObj.Mall.MallId,
          Message: msg,
          ProfileId: authObj.Profile.ProfileId
        };

        return this.httpClient.post(SERVER_URL + '/shop/Invitefriend', JSON.stringify(data))
        .pipe(
          map(response => {
              const responseJSON = JSON.parse(JSON.stringify(response));
              if (responseJSON.ResponseData.Type === 'Success') {
                return true;
              } else {
                throw new Error(responseJSON.ResponseData.Data.Message);
              }
            })
        );
      });
  }

  public getPaymentRequest(): Promise<Observable<any>> {
    return this.commonService.isAuthenticated().then((authObj:AuthObject)=>{
      let Params = new HttpParams();
      Params = Params.append('mallId', authObj.Mall.MallId);
      Params = Params.append('profileId', authObj.Profile.ProfileId);

      return this.httpClient
      .get(SERVER_URL + '/Transactions/GetPaymentRequest', {params: Params})
      .pipe(
        map(response => {
            const responseJSON = JSON.parse(JSON.stringify(response));
            if (responseJSON.ResponseData.Type === 'Success') {
              responseJSON.ResponseData.Data.MinimumEligibleAmount=authObj.Mall.MinimumEligibleAmount;
              return responseJSON.ResponseData.Data;
            } else {
              throw new Error(responseJSON.ResponseData.Data.Message);
            }
          })
      );
    });
  }

  public resendPRVerificationEmail(): Promise<boolean>{
    return this.commonService.isAuthenticated().then((authObj:AuthObject)=>{
      let Params = new HttpParams();
      Params = Params.append('mallId', authObj.Mall.MallId);
      Params = Params.append('profileId', authObj.Profile.ProfileId);

      return this.httpClient
      .get(SERVER_URL + '/Transactions/SendPRVerificationMail', {params: Params})
      .toPromise()
      .then(response => {
            const responseJSON = JSON.parse(JSON.stringify(response));
            if (responseJSON.ResponseData.Type === 'Success') {
              return true;
            } else {
              throw new Error(responseJSON.ResponseData.Data.Message);
            }
        });
    });
  }

  public saveRequestPayment(
    payableTo: string, 
    country: string, 
    state: string, 
    city: string, 
    zip: string, 
    address1: string, 
    address2: string,
    phone:string,
    isPaypalPayment:boolean,
    email: string): Promise<Observable<boolean>> {
      return this.commonService.isAuthenticated().then((authObj:AuthObject)=>{
        const data = {
          MallId: authObj.Mall.MallId,
          ProfileId: authObj.Profile.ProfileId,
          payableTo: payableTo,
          country: country,
          state: state,
          city: city,
          zip: zip,
          address1: address1,
          address2: address2,
          phone:phone,
          isPaypalPayment:isPaypalPayment,
          email: email
        };

        return this.httpClient.post(SERVER_URL + '/Transactions/SaveRequestPayment', JSON.stringify(data))
        .pipe(
          map(response => {
              const responseJSON = JSON.parse(JSON.stringify(response));
              if (responseJSON.ResponseData.Type === 'Success') {
                return true;
              } else {
                throw new Error(responseJSON.ResponseData.Data.Message);
              }
            })
        );
    });
  }

  public ActivateUser(username:string): Observable<ResponseData>{
    let Params = new HttpParams();
    Params = Params.append('userName', username);
    
    return this.httpClient
    .get(SERVER_URL + '/Profile/ActivateUser', {params: Params})
    .pipe(
      map(response => {
        const responseJSON = JSON.parse(JSON.stringify(response));
        if (responseJSON.ResponseData.Type === 'Success') {
          return new ResponseData(responseJSON.ResponseData);
          } else {
            throw new Error(responseJSON.ResponseData.Data.Message);
          }
      })
    );
  }

  public getCurrentReferralValidate(
    referral: string,
    refreshCache: boolean): Promise<ResponseData> {
      return this.commonService.isAuthenticated().then((authObj:AuthObject)=>{
        let Params = new HttpParams();
        Params = Params.append('mallId', authObj.Mall.MallId);
        // Params = Params.append('profileId', authObj.Profile.ProfileId);
        Params = Params.append('referral', referral);

        var cacheKey = `CurrentReferral_${referral}`;

        if(refreshCache){
          this.cachingService.invalidateCacheEntry(cacheKey);
        }
        
        return this.cachingService.getCachedData(cacheKey).then((data)=>{
          if(data!=null){
            
            return data;
          }else{
            return this.httpClient
            .get(SERVER_URL + '/Profile/ValidateReferral_', {params: Params})
            .toPromise()
            .then(response => {
                const responseJSON = JSON.parse(JSON.stringify(response));
                if (responseJSON.ResponseData.Type === 'Success') {
                  this.cachingService.cacheData(cacheKey,responseJSON.ResponseData);
                  
                  return new ResponseData(responseJSON.ResponseData);
                } else {
                  throw new Error(responseJSON.ResponseData.Data.Message);
                }
              });
          }
        });
      });
  }

 
}
