import { Component, NgZone } from '@angular/core';

import { Platform } from '@ionic/angular';
import { StatusBar, Style } from '@capacitor/status-bar';
import { CachingService } from './services/caching.service';

import { register } from 'swiper/element/bundle';
import { SplashScreen } from '@capacitor/splash-screen';
import { Capacitor } from '@capacitor/core';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import { Router } from '@angular/router';

register();

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  constructor(
    private platform: Platform,
    private router: Router,
    private zone: NgZone,
    private cachingService: CachingService
  ) {
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.cachingService.initStorage();
      
      //SplashScreen.hide();

      if(Capacitor.isNativePlatform()){
        StatusBar.setStyle({ style: Style.Light });
        StatusBar.show();
      }
    });
  }
}
