<ion-header>
    <ion-toolbar>
      <ion-buttons slot="end">
        <ion-icon size="large" (click)="continueAsGuest();" name="close-circle-outline"></ion-icon>
      </ion-buttons>
    </ion-toolbar>
    <ion-toolbar class="ion-text-center">
      <img src="assets/imgs/site-logo.png" style="width:50%;" />
    </ion-toolbar>
</ion-header>
  
  <ion-content class="ion-padding">
      <form class="form small-font" *ngIf="formName==='signin'" [formGroup]="loginForm" (ngSubmit)="loginFormSubmit(loginForm.value)">
        <ion-row>
          <ion-col>
            <ion-item>
              <ion-input type="email" formControlName="username" placeholder="Email"></ion-input>
            </ion-item>
            <div validation-errors>
              <ng-container *ngFor="let validation of validationMessages.username">
                <div class="error-message" *ngIf="loginForm.get('username').hasError(validation.type) && (loginForm.get('username').dirty || loginForm.get('username').touched)">
                  {{ validation.message }}
                </div>
              </ng-container>
            </div>
            <ion-item>
              <ion-input type="password" formControlName="password" placeholder="Password"></ion-input>
            </ion-item>
            <div validation-errors>
              <ng-container *ngFor="let validation of validationMessages.password">
                <div class="error-message " *ngIf="loginForm.get('password').hasError(validation.type) && (loginForm.get('password').dirty || loginForm.get('password').touched)">
                  {{ validation.message }}
                </div>
              </ng-container>
            </div>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col class="ion-text-right small-font">
            <ion-label><a (click)="formName='recoverpwd'">Forgotten password?</a></ion-label>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col class="ion-text-center">
              <ion-button full type="submit" shape="round" [disabled]="!loginForm.valid">SIGN IN</ion-button>
          </ion-col>
        </ion-row>
      </form>

      <form class="form small-font" *ngIf="formName==='signup'" [formGroup]="signupForm" (ngSubmit)="signupFormSubmit(signupForm.value)">
        <ion-row>
            <ion-col>
            <!-- <ion-item>You are joining under {{conf.ParentRefCode}}</ion-item> -->
            <ion-item>
              <ion-input type="text" formControlName="firstname" placeholder="First Name"></ion-input>
              <ion-input type="text" formControlName="lastname" placeholder="Last Name"></ion-input>
            </ion-item>
            <ion-item>
              <ion-input type="email" formControlName="username" placeholder="Email"></ion-input>
            </ion-item>
            <div validation-errors>
              <ng-container *ngFor="let validation of validationMessages.username">
                <div class="error-message" *ngIf="signupForm.get('username').hasError(validation.type) && (signupForm.get('username').dirty || signupForm.get('username').touched)">
                  {{ validation.message }}
                </div>
              </ng-container>
            </div>
            <ion-item>
              <ion-input type="password" formControlName="password" placeholder="Password (minimum 6 character)"></ion-input>
            </ion-item>
            <div validation-errors>
              <ng-container *ngFor="let validation of validationMessages.password">
                <div class="error-message " *ngIf="signupForm.get('password').hasError(validation.type) && (signupForm.get('password').dirty || signupForm.get('password').touched)">
                  {{ validation.message }}
                </div>
              </ng-container>
            </div>
            <ion-item>
                <ion-label class="small-font">Yes! send me email updates <br/> and offers</ion-label>
                <ion-checkbox formControlName="canEmail" color="primary" checked slot="start" aria-label="Label"></ion-checkbox>
              </ion-item>
            </ion-col>
          </ion-row>
          <ion-row>
              <ion-col class="ion-text-center">
                  <ion-button full type="submit" shape="round" [disabled]="!signupForm.valid">SIGN UP</ion-button>
                  <p class="ion-text-center">
                      By Signing up, you agree to our <a href="/#/tabs/agreement" target="_blank">Membership Agreement</a> and <a href="/#/tabs/privacy" target="_blank">Privacy Policy</a>
                  </p>
                  <p *ngIf="signupBonus > 0" class="ion-text-left">
                    Upon sign up, you'll receive ${{signupBonus}} Cash Rebate welcome bonus!
                  </p>
              </ion-col>
            </ion-row>
      </form>
      <p class="ion-text-center" *ngIf="formName==='signup'">
        Already a member? <a (click)="formName='signin'">SIGN IN</a>
      </p>

      <p class="ion-text-center" *ngIf="formName==='signin' || formName==='recoverpwd'">
          Not a member? <a (click)="formName='signup'">SIGN UP</a>
      </p>


      <form class="form" *ngIf="formName==='recoverpwd'" [formGroup]="recoverForm" (ngSubmit)="recoveryFormSubmit(recoverForm.value)">
        <p>
          Please provide your email address below to recover your password
        </p>
        <ion-item>
          <ion-input type="email" formControlName="username" placeholder="Email"></ion-input>
        </ion-item>
        <div validation-errors>
          <ng-container *ngFor="let validation of validationMessages.username">
            <div class="error-message" *ngIf="recoverForm.get('username').hasError(validation.type) && (recoverForm.get('username').dirty || recoverForm.get('username').touched)">
              {{ validation.message }}
            </div>
          </ng-container>
        </div>
        <ion-row>
          <ion-col class="ion-text-center">
              <ion-button full (click)="formName='signin'">Cancel</ion-button>
              <ion-button full type="submit" [disabled]="!recoverForm.valid">Recover</ion-button>
          </ion-col>
        </ion-row>
      </form>

      <form class="form" [formGroup]="exSignUpForm" (ngSubmit)="exSignup(exSignUpForm.value)" *ngIf="formName==='exSignup'"> 
        <p class="ion-text-center small-font">
          By Signing up, you agree to our <a (click)="showPage('agreement');">Membership Agreement</a> and <a (click)="showPage('privacy');">Privacy Policy</a>
        </p>
        <ion-item *ngIf="showEmailInput">
            <ion-input type="email" formControlName="username" placeholder="Email"></ion-input>
        </ion-item>
        <div *ngIf="showEmailInput" validation-errors>
              <ng-container *ngFor="let validation of validationMessages.username">
                <div class="error-message" *ngIf="exSignUpForm.get('username').hasError(validation.type) && (exSignUpForm.get('username').dirty || exSignUpForm.get('username').touched)">
                  {{ validation.message }}
                </div>
              </ng-container>
          </div>
        <ion-item>
          <ion-checkbox formControlName="chkCanEmail" color="primary" class="ion-margin"></ion-checkbox>
          <ion-label><p>Yes! send me email updates and offers</p></ion-label>
        </ion-item>
        <ion-row>
          <ion-col class="ion-text-center">
            <ion-button ion-button full *ngIf="showEmailInput" type="submit" [disabled]="!exSignUpForm.valid">Signup</ion-button>
            <ion-button ion-button full *ngIf="!showEmailInput" type="submit">Signup</ion-button>
          </ion-col>
        </ion-row>
      </form>

      <!-- <ion-item-divider *ngIf="formName!='exSignup'"></ion-item-divider> -->
      <ion-row *ngIf="formName!='exSignup'">
        <ion-col class="ion-text-center">
          <ion-button (click)="loginWithGoogle();" id="btnGoogle">
            <ion-icon slot="start" src='{{"assets/imgs/google.svg"}}'></ion-icon>
            Continue with Google
          </ion-button>
        </ion-col>
      </ion-row>

      <ion-row *ngIf="formName!='exSignup'" >
        <ion-col class="ion-text-center">
          <ion-button (click)="loginWithFacebook();">
            <ion-icon slot="start" name="logo-facebook"></ion-icon>
            Continue with Facebook
          </ion-button>
        </ion-col>
      </ion-row>
  
      <ion-row *ngIf="formName!='exSignup' && showAppleSignIn" >
        <ion-col class="ion-text-center">
          <ion-button (click)="openAppleSignIn()" id="btnApple">
            <ion-icon name="logo-apple" slot="start"></ion-icon>
            Continue with Apple
          </ion-button>
        </ion-col>
      </ion-row>
    </ion-content>
   
  