import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SERVER_URL } from '../../environments/environment';
import { AuthObject } from '../classes/profile';
import { ResponseData, Category, Store, HomeModel, Merchant } from '../classes/store';

import { CommonService } from './common.service';
import { AlertController, LoadingController } from '@ionic/angular';
import { CachingService } from './caching.service';
import { Capacitor } from '@capacitor/core';
@Injectable({
  providedIn: 'root'
})

export class StoreService {
  constructor(
    private alertCtrl: AlertController,
    private loadingCtrl: LoadingController,
    private httpClient: HttpClient,
    private commonService: CommonService,
    private cachingService: CachingService) { }

  public getMerchants(
    pageNumber: number,
    pageSize: number,
    type: string,
    searchTerm: string,
    categoryId: number,
    hasDeals: boolean,
    hasBestDeals: boolean,
    refreshCache: boolean): Promise<ResponseData> {
      return this.commonService.isAuthenticated().then((authObj:AuthObject)=>{
        let Params = new HttpParams();
        Params = Params.append('mallId', authObj.Mall.MallId);
        Params = Params.append('profileId', authObj.Profile.ProfileId);
        Params = Params.append('isLocal', 'false');
        Params = Params.append('pageNumber', pageNumber.toString());
        Params = Params.append('pageSize', pageSize.toString());
        Params = Params.append('type', type);
        Params = Params.append('refreshCache', refreshCache);

        var cacheKey = `Merchants_${pageNumber}_${pageSize}_${type}`;

        if (hasDeals !== undefined) {
          Params = Params.append('hasOffers', hasDeals.toString());
          cacheKey=`${cacheKey}_${hasDeals}`;
        }

        if (hasBestDeals !== undefined) {
          Params = Params.append('hasBestDeals', hasBestDeals.toString());
          cacheKey=`${cacheKey}_${hasBestDeals}`;
        }

        if (searchTerm !== undefined) {
          Params = Params.append('searchTerm', searchTerm);
          cacheKey=`${cacheKey}_${searchTerm}`;
        }

        if (categoryId !== undefined) {
          Params = Params.append('categoryId', categoryId.toString());
          cacheKey=`${cacheKey}_${categoryId}`;
        }

        if(refreshCache){
          this.cachingService.invalidateCacheEntry(cacheKey);
        }
        
        return this.cachingService.getCachedData(cacheKey).then((data)=>{
          if(data!=null){
            
            return data;
          }else{
            return this.httpClient
            .get(SERVER_URL + '/shop/Merchants_', {params: Params})
            .toPromise()
            .then(response => {
                const responseJSON = JSON.parse(JSON.stringify(response));
                if (responseJSON.ResponseData.Type === 'Success') {
                  this.cachingService.cacheData(cacheKey,responseJSON.ResponseData);
                  
                  return new ResponseData(responseJSON.ResponseData);
                } else {
                  throw new Error(responseJSON.ResponseData.Data.Message);
                }
              });
          }
        });
      });
  }

  public getAlphabetMerchants(
    pageNumber: number,
    pageSize: number,
    letter: string,
    refreshCache: boolean): Promise<ResponseData> {
      return this.commonService.isAuthenticated().then((authObj:AuthObject)=>{
        let Params = new HttpParams();
        Params = Params.append('mallId', authObj.Mall.MallId);
        Params = Params.append('profileId', authObj.Profile.ProfileId);
        Params = Params.append('isLocal', 'false');
        Params = Params.append('pageNumber', pageNumber.toString());
        Params = Params.append('pageSize', pageSize.toString());
        Params = Params.append('refreshCache', refreshCache);

        var cacheKey = `AlphabMerchants_${pageNumber}_${pageSize}`;


        if (letter !== undefined) {
          Params = Params.append('letter', letter);
          cacheKey=`${cacheKey}_${letter}`;
        }


        if(refreshCache){
          this.cachingService.invalidateCacheEntry(cacheKey);
        }
        
        return this.cachingService.getCachedData(cacheKey).then((data)=>{
          if(data!=null){
            
            return data;
          }else{
            return this.httpClient
            .get(SERVER_URL + '/shop/AllMerchantsWithLetter_', {params: Params})
            .toPromise()
            .then(response => {
                const responseJSON = JSON.parse(JSON.stringify(response));
                if (responseJSON.ResponseData.Type === 'Success') {
                  this.cachingService.cacheData(cacheKey,responseJSON.ResponseData);
                  
                  return new ResponseData(responseJSON.ResponseData);
                } else {
                  throw new Error(responseJSON.ResponseData.Data.Message);
                }
              });
          }
        });
      });
  }

  public getDeals(
      pageNumber: number,
      pageSize: number,
      type: string,
      storeId: number,
      isbestdeals : boolean,
      offertype : number,
      refreshCache: boolean): Promise<ResponseData> {
        
       return this.commonService.isAuthenticated().then((authObj:AuthObject)=>{
          let Params = new HttpParams();
            Params = Params.append('mallId', authObj.Mall.MallId);
            Params = Params.append('profileId', authObj.Profile.ProfileId);
            Params = Params.append('pageNumber', pageNumber.toString());
            Params = Params.append('pageSize', pageSize.toString());
            Params = Params.append('type', type);
            Params = Params.append('isbestdeals', isbestdeals.toString());
            Params = Params.append('offertype', offertype.toString());
            Params = Params.append('refreshCache', refreshCache);
        
            var cacheKey = `Deals_${pageNumber}_${pageSize}_${type}_${isbestdeals}_${offertype}`;

            if (storeId !== undefined) {
              Params = Params.append('merchantId', storeId.toString());
              cacheKey=`${cacheKey}_${storeId}`;
            }

            if(refreshCache){
              this.cachingService.invalidateCacheEntry(cacheKey);
            }

            return this.cachingService.getCachedData(cacheKey).then((data)=>{
              if(data!=null){
                
                return data;
              }else{
                return this.httpClient.get(SERVER_URL + '/shop/offerswithmerchant', {params: Params})
                .toPromise()
                .then(response => {
                    const responseJSON = JSON.parse(JSON.stringify(response));
                    if (responseJSON.ResponseData.Type === 'Success') {
                      var responseData= new ResponseData(responseJSON.ResponseData);
                      responseData.AuthObj=authObj;
                      this.cachingService.cacheData(cacheKey,responseData);
                      
                      return responseData;
                    } else {
                      throw new Error(responseJSON.ResponseData.Data.Message);
                    }
                });
              }
            });
        });
  }
  
  public getHomePageData(
    pageNumber:number,
    pageSize:number,
    type:string,
    storeId:number,
    refreshCache:boolean): Promise<HomeModel> {
        
    return this.commonService.isAuthenticated().then((authObj:AuthObject)=>{
      let Params = new HttpParams();
      Params=Params.append('mallId',authObj.Mall.MallId);
      Params=Params.append('profileId',authObj.Profile.ProfileId);
      Params=Params.append('pageNumber',pageNumber.toString());
      Params=Params.append('pageSize',pageSize.toString());
      Params=Params.append('type',type);
      Params=Params.append('refreshCache',refreshCache);
  
      var cacheKey = `HPD_${pageNumber}_${pageSize}_${type}`;

      if(storeId!=undefined) { 
        Params=Params.append('merchantId',storeId.toString());
        cacheKey=`${cacheKey}_${storeId}`;
      }

      if(refreshCache){
        this.cachingService.invalidateCacheEntry(cacheKey);
      }
    
      return this.cachingService.getCachedData(cacheKey).then((data)=>{
        if(data!=null){
          
          return new HomeModel(data);
        }else{
          return this.httpClient.get(SERVER_URL + '/shop/AllSearchData', {params: Params})
          .toPromise()
          .then(response => {
              const responseJSON = JSON.parse(JSON.stringify(response));
              if (responseJSON.ResponseData.Type === 'Success') {
                this.cachingService.cacheData(cacheKey,responseJSON.ResponseData.Data.homeModel);
                
                return new HomeModel(responseJSON.ResponseData.Data.homeModel);
              } else {
                throw new Error(responseJSON.ResponseData.Data.Message);
              }
          });
        }
      });
    });
  }

  public async RegisterClick(store: Store, offerId: string){
    this.commonService.RegisterClick(store,offerId);
  }

  public getCategories(isLocal: boolean): Promise<Category[]> {
    return this.commonService.isAuthenticated().then((authObj:AuthObject)=>{
      let Params = new HttpParams();
      Params = Params.append('mallId', authObj.Mall.MallId);
      Params = Params.append('isLocal', isLocal.toString());
      var cacheKey = 'Categories';

      return this.cachingService.getCachedData(cacheKey).then((data)=>{
        if(data!=null){
          return data;
        }else{
          return this.httpClient
          .get(SERVER_URL + '/shop/Categories_', {params: Params})
          .toPromise()
          .then(response => {
              const responseJSON = JSON.parse(JSON.stringify(response));
              if (responseJSON.ResponseData.Type === 'Success') {
                this.cachingService.cacheData(cacheKey,responseJSON.ResponseData.Data.map(data => new Category(data)));
                
                return responseJSON.ResponseData.Data.map(data => new Category(data));
              } else {
                throw new Error(responseJSON.ResponseData.Data.Message);
              }
            });
          }
      });
   });
  }

  public ManageFavoriteStore(storeId:number, deleteThis:boolean): Promise<Observable<boolean>>{
    return this.commonService.isAuthenticated().then((authObj:AuthObject)=>{
      let Params = new HttpParams();
      Params = Params.append('mallId', authObj.Mall.MallId);
      Params = Params.append('profileId', authObj.Profile.ProfileId);
      Params = Params.append('storeId', storeId);
      Params = Params.append('deleteThis', deleteThis);

      return this.httpClient
        .get(SERVER_URL + '/shop/ManageFavoriteStore', {params: Params})
        .pipe(
          map(response => {
            const responseJSON = JSON.parse(JSON.stringify(response));
            if (responseJSON.ResponseData.Type === 'Success') {
              return true;
            } else {
              throw new Error(responseJSON.ResponseData.Data.Message);
            }
          })
        );
      });
  }

  public GetFavoriteStore(mallId:string, profileId: string): Promise<Store[]>{
        let Params = new HttpParams();
        Params = Params.append('mallId', mallId);
        Params = Params.append('profileId', profileId);

        var cacheKey = 'FavStores';

        return this.cachingService.getCachedData(cacheKey).then((data)=>{
          if(data!=null){
            return data;
          }else{
            return this.httpClient
            .get(SERVER_URL + '/shop/GetFavoriteStores', {params: Params})
            .toPromise()
            .then(response => {
                const responseJSON = JSON.parse(JSON.stringify(response));
                if (responseJSON.ResponseData.Type === 'Success') {
                  this.cachingService.cacheData(cacheKey,responseJSON.ResponseData.Data.map(data => new Store(data)));
                  
                  return responseJSON.ResponseData.Data.map(data => new Store(data));
                } else {
                  throw new Error(responseJSON.ResponseData.Data.Message);
                }
              });
            }
        });
      }
}
