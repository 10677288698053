// httpcancel.service.ts
// https://www.freakyjolly.com/angular-how-to-cancel-http-calls-on-router-change/#more-2919
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
 
@Injectable()
export class HttpCancelService {
 
    private pendingHTTPRequests$ = new Subject<void>();
 
  constructor() { }
 
  // Cancel Pending HTTP calls
  public cancelPendingRequests() {
    this.pendingHTTPRequests$.next();
  }
 
  public onCancelPendingRequests() {
    return this.pendingHTTPRequests$.asObservable();
  }
 
}