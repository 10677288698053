import { Component, OnInit } from '@angular/core';
import { AlertController, LoadingController, ModalController  } from '@ionic/angular';
import { Validators, FormGroup, FormControl  } from '@angular/forms';
import { AuthService } from '../services/auth.service';
import { ExternalAuthDetails, AuthObject, Configuration } from '../classes/profile';

import { GoogleAuth } from "@codetrix-studio/capacitor-google-auth";
import { FacebookLogin } from '@capacitor-community/facebook-login';


import {SignInWithApple, SignInWithAppleOptions} from "@capacitor-community/apple-sign-in";

import { Device } from '@capacitor/device';
import { Preferences } from '@capacitor/preferences';
import { CommonService } from '../services/common.service';

@Component({
  selector: 'app-openshopurl',
  templateUrl: './openshopurl.page.html',
  styleUrls: ['./openshopurl.page.scss'],
})
export class OpenShopUrlPage implements OnInit {
  conf:Configuration;
  url:string="";
  CashBackTextOnly:string="";
  storeName:string="";
  constructor(
    private authService: AuthService,
    private alertCtrl: AlertController,
    private loadingCtrl: LoadingController,
    private modalCtrl: ModalController,
    private commonService: CommonService) {

    this.conf=new Configuration();
   }

  async ngOnInit() {
   
  }

  async cancel() {
     await this.modalCtrl.dismiss("dismiss");
  }

}
