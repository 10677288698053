import { Component, OnInit } from '@angular/core';
import { AlertController, LoadingController, ModalController  } from '@ionic/angular';
import { Validators, FormGroup, FormControl  } from '@angular/forms';
import { AuthService } from '../services/auth.service';
import { ExternalAuthDetails, AuthObject, Configuration } from '../classes/profile';

import { GoogleAuth } from "@codetrix-studio/capacitor-google-auth";
import { FacebookLogin } from '@capacitor-community/facebook-login';


import {SignInWithApple, SignInWithAppleOptions} from "@capacitor-community/apple-sign-in";

import { Device } from '@capacitor/device';
import { Preferences } from '@capacitor/preferences';
import { CommonService } from '../services/common.service';
import { Capacitor } from '@capacitor/core';

@Component({
  selector: 'app-joinnow',
  templateUrl: './joinnow.page.html',
  styleUrls: ['./joinnow.page.scss'],
})
export class JoinnowPage implements OnInit {
  formName:string="signup";
  signupBonus:number;

  signupForm: FormGroup;
  loginForm: FormGroup;
  recoverForm: FormGroup;
  exSignUpForm: FormGroup;
  chkCanEmail:string="false";
  showEmailInput:boolean = false;

  exAuthToken:string;
  exProvider:string;

  //FOR APPLE
  appleFamilyName:string;
  appleGivenName:string;
  appleUserId:string;
  appleEmail:string;
  appleToken:string;
 

  authenticated = false;
  validationMessages = {
    username: [
      { type: 'required', message: 'Username is required.' },
      { type: 'pattern', message: 'Your username must contain email address' },
    ],
    password: [
      { type: 'required', message: 'Password is required.' }
    ],
  };

  DeviceOSName:string;
  ThisAppVersion:string = 'NA';

  showAppleSignIn = false;
  isGuestLogin:boolean=false;
  conf:Configuration;
  
  constructor(
    private authService: AuthService,
    private alertCtrl: AlertController,
    private loadingCtrl: LoadingController,
    private modalCtrl: ModalController,
    private commonService: CommonService) {

    this.loginForm = new FormGroup({
      username: new FormControl('', Validators.compose([
        Validators.required,
        Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')
      ])),
      password: new FormControl('', Validators.required)
    });

    this.signupForm = new FormGroup({
      firstname: new FormControl('', Validators.required),
      lastname: new FormControl('', Validators.required),
      username: new FormControl('', Validators.compose([
        Validators.required,
        Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')
      ])),
      password: new FormControl('', [Validators.required, Validators.minLength(6)]),
      canEmail: new FormControl(false, Validators.required ),
    });

    this.recoverForm = new FormGroup({
      username: new FormControl('', Validators.compose([
        Validators.required,
        Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')
      ])),
    });

    this.conf=new Configuration();
   }

  async ngOnInit() {
    this.conf=await this.commonService.getAppConfiguration();
    const info = await Device.getInfo();
    this.DeviceOSName=info.platform;
    this.ThisAppVersion=info.osVersion;

    this.showAppleSignIn = info.platform === 'ios';

    Preferences.get({ key: 'authObject' }).then(ret => {
      const authObj = JSON.parse(ret.value);
      if (authObj!=null) {
        this.isGuestLogin=authObj.IsGuestLogin;
        this.signupBonus=authObj.Mall.SignupBonus;
      }
      
    });

    if(!Capacitor.isNativePlatform()){
      this.showAppleSignIn=true;

      await FacebookLogin.initialize({ appId: '1665762573676387' });

      await GoogleAuth.initialize({
        clientId: '252271114067-934a8dbhipk73jgdut5v3h0r5hdcbh3c.apps.googleusercontent.com',
        scopes: ['profile', 'email'],
        grantOfflineAccess: true,
      });
    }
  }

  async continueAsGuest() {
   if(this.isGuestLogin){
    await this.modalCtrl.dismiss("dismiss");
   }else{
    const alert = await this.alertCtrl.create({
      header: 'Are you sure?',
      message: 'Are you sure to continue without sign in? Please remember that you\'ll not earn any cashback without sign in.',
      buttons: [
        { text: 'Cancel' },
        {
          text: 'Continue',
          handler: async () => {
            const values = {
              username: 'guestToken',
              password: this.conf.GuestPasswordHash
            };
            const loading = await this.loadingCtrl.create({
              spinner: 'crescent',
              message: 'Please wait...',
            });
            await loading.present();
            this.authService.login(values.username,values.password,true,(data)=>{
              loading.dismiss();
              if(data==="success"){
                this.modalCtrl.dismiss();
              }
            })
          }
        },
      ]
    });
    await alert.present();
   }
  }

  
  async loginFormSubmit(values) {
    const loading = await this.loadingCtrl.create({
      spinner: 'crescent',
      message: 'Please wait...',
      duration:15000
    });
    await loading.present();
    this.authService.login(values.username,values.password,false,(data)=>{
      if(data==="success"){
        this.modalCtrl.dismiss();
      }
      loading.dismiss();
    })
    .catch(()=>{
      this.modalCtrl.dismiss();
      loading.dismiss();
    });
  }

  async signupFormSubmit(values) {
    const loadingRegister = await this.loadingCtrl.create({
      spinner: 'crescent',
      message: 'Please Wait...'
    });

    const confirm = await this.alertCtrl.create({
      header: 'Please confirm',
      message: 'By Signing up, you agree to our Membership Agreement and Privacy Policy',
      buttons: [
        {
          text: 'Disagree'
        },
        {
          text: 'Agree',
          handler: async () => {
            await loadingRegister.present();
            this.authService.RegisterWithEmail(this.conf.MallId, this.conf.ParentRefCode, values.firstname,
              values.lastname,values.username,values.password,values.canEmail,
              this.DeviceOSName,this.ThisAppVersion,(data)=>{
                loadingRegister.dismiss();
                if(data==="success"){
                  this.modalCtrl.dismiss();
                }
            })
          }
        }
      ]
    });

    await confirm.present();
  }

  async recoveryFormSubmit(values) {
    const loading = await this.loadingCtrl.create({
      spinner: 'crescent',
      message: 'Please wait...',
    });
    await loading.present();
    this.authService.recoverPassword(values.username,(data)=>{
      loading.dismiss();
      if(data==="success"){
        this.formName='signin';
      }
    });
  }

  async loginWithFacebook() {
    const loading = await this.loadingCtrl.create({
      spinner: 'crescent',
      message: 'Please wait...',
      duration: 15000
    });
    await loading.present();
    const FACEBOOK_PERMISSIONS = ['public_profile', 'email'];

    await FacebookLogin.login({ permissions: FACEBOOK_PERMISSIONS }).then(async (result)=>{
      if (result && result.accessToken) {
        this.authService.exAuthToken = result.accessToken.token;
        this.authService.exAuthProvider = 'fb';
        this.authService.isFacebookLogin = true;
        this.authService.isGooglePlusLogin = false;
        const exAuthObj = new ExternalAuthDetails();
        exAuthObj.MallId = this.conf.MallId;
        exAuthObj.ParentReferralCode=this.conf.ParentRefCode;
        exAuthObj.ExAuthToken = result.accessToken.token;
        exAuthObj.ExProvider = 'fb';
        exAuthObj.CreateCaptcha = false;

        // Set extoken and provider info in case login fails and this can pass these info on next step
        const authObject: AuthObject = new AuthObject();
        authObject.ExAuthToken = exAuthObj.ExAuthToken;
        authObject.ExProvider = exAuthObj.ExProvider;
        //this.storage.set('authObject', authObject);
        this.authService.loginWithExternalToken(exAuthObj, this.DeviceOSName, this.ThisAppVersion, (data)=>{
          loading.dismiss();
          if(data==="success"){
            this.modalCtrl.dismiss();
          }else if(data==="exSignup"){
            this.formName="exSignup";
            this.showEmailInput=false;
            this.exAuthToken=exAuthObj.ExAuthToken;
            this.exProvider=exAuthObj.ExProvider;
            this.exSignUpForm = new FormGroup({
              chkCanEmail: new FormControl(true, Validators.pattern('true'))
            });
          }else if(data==="exSignupWithEmail"){
            this.formName="exSignup";
            this.showEmailInput=true;
            this.exAuthToken=exAuthObj.ExAuthToken;
            this.exProvider=exAuthObj.ExProvider;
            this.exSignUpForm = new FormGroup({
              username: new FormControl('', Validators.compose([
                Validators.required,
                Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')
              ])),
              chkCanEmail: new FormControl(true, Validators.pattern('true'))
            });
          }
        });
      }
    })
    .catch(()=>{
      loading.dismiss();
    });
  }

  // GOOGLE LOGIN/REGISTRATION
  async loginWithGoogle() {
    const loading = await this.loadingCtrl.create({
      spinner: 'crescent',
      message: 'Please wait...',
      duration: 15000
    });
    await loading.present();
    
    

    await GoogleAuth.signIn().then(async (googleUser)=>{
      this.authService.exAuthToken = googleUser.authentication.idToken;
      this.authService.exAuthProvider = 'google';
      const exAuthObj = new ExternalAuthDetails();
      exAuthObj.MallId = this.conf.MallId;
      exAuthObj.ParentReferralCode=this.conf.ParentRefCode;
      exAuthObj.ExAuthToken = googleUser.authentication.idToken;
      exAuthObj.ExProvider = 'google';
      exAuthObj.CreateCaptcha = false;
      this.exProvider=exAuthObj.ExProvider;

      // Set extoken and provider info incase login fails and this can pass these info on next step
      const authObject: AuthObject = new AuthObject();
      authObject.ExAuthToken = exAuthObj.ExAuthToken;
      authObject.ExProvider = exAuthObj.ExProvider;

      this.authService.loginWithExternalToken(exAuthObj, this.DeviceOSName, this.ThisAppVersion, (data)=>{
        loading.dismiss();
        if(data==="success"){
          this.modalCtrl.dismiss();
        }else if(data==="exSignup"){
          this.formName="exSignup";
          this.showEmailInput=false;
          this.exAuthToken=exAuthObj.ExAuthToken;
          this.exProvider=exAuthObj.ExProvider;
          this.exSignUpForm = new FormGroup({
            chkCanEmail: new FormControl(true, Validators.pattern('true'))
          });
        }
      });
    }).catch(()=>{
      loading.dismiss();
    });
  }

  async openAppleSignIn() {
    const loading = await this.loadingCtrl.create({
      spinner: 'crescent',
      message: 'Please wait...',
      duration: 15000
    });
    await loading.present();
    let options: SignInWithAppleOptions = {
      clientId: 'com.myvir.client',
      redirectURI: 'https://www.cashrebateclub.com',
      scopes: 'email name',
      state : 'vir',
      nonce : 'vir',
    };
    
    await SignInWithApple.authorize(options)
      .then(async (res) => {
        
        if (res.response && res.response.identityToken) {
          this.authService.exAuthToken = res.response.authorizationCode;
          this.authService.exAuthProvider = 'apple';
         
          const exAuthObj = new ExternalAuthDetails();
          exAuthObj.MallId = this.conf.MallId;
          exAuthObj.ParentReferralCode=this.conf.ParentRefCode;
          exAuthObj.ExAuthToken = res.response.authorizationCode;
          this.appleToken=res.response.identityToken;
          exAuthObj.ExProvider = 'apple';
          exAuthObj.CreateCaptcha = false;
          exAuthObj.GivenName=res.response.givenName;
          exAuthObj.FamilyName=res.response.familyName;
          exAuthObj.UserId=res.response.user;

          if(res.response.email!=null && res.response.email!=undefined){
            exAuthObj.ExUserName=res.response.email;
            this.appleEmail=res.response.email;
          }

          this.appleGivenName=res.response.givenName;
          this.appleFamilyName=res.response.familyName;
          this.appleUserId=res.response.user;

          
          this.exProvider=exAuthObj.ExProvider;

          // Set extoken and provider info in case login fails and this can pass these info on next step
          const authObject: AuthObject = new AuthObject();
          authObject.ExAuthToken = exAuthObj.ExAuthToken;
          authObject.ExProvider = exAuthObj.ExProvider;

          this.authService.loginWithExternalToken(exAuthObj, this.DeviceOSName, this.ThisAppVersion, (data)=>{
            loading.dismiss();
            if(data==="success"){
              this.modalCtrl.dismiss();
            }else if(data==="exSignup"){
              this.formName="exSignup";
              this.showEmailInput=false;
              this.exAuthToken=exAuthObj.ExAuthToken;
              this.exProvider=exAuthObj.ExProvider;
              
              this.exSignUpForm = new FormGroup({
                chkCanEmail: new FormControl(true, Validators.pattern('true'))
              });
            }
          });
        }
      })
      .catch(() => {
        loading.dismiss();
        this.presentAlert();
      });
  }

  async presentAlert() {
    const alert = await this.alertCtrl.create({
      header: 'Login Failed',
      message: 'Please try again later',
      buttons: ['OK'],
    });
    await alert.present();
  }

  async exSignup(values){
    const loading= await this.loadingCtrl.create({
      spinner: 'crescent',
      message: 'Please Wait...'
    });
    await loading.present();

    var exAuthObj=new ExternalAuthDetails();
    exAuthObj.MallId = this.conf.MallId;
    exAuthObj.ParentReferralCode=this.conf.ParentRefCode;
    
    exAuthObj.ExAuthToken=this.exAuthToken;
    exAuthObj.ExProvider=this.exProvider;

    if(values.username!=undefined){
      exAuthObj.CustomEmail = values.username;
    }
    exAuthObj.CreateCaptcha=true;
    exAuthObj.CanEmail=this.chkCanEmail.toString().indexOf("true")>-1;

    if(this.exProvider=='apple'){
      exAuthObj.UserId=this.appleUserId;
      exAuthObj.FamilyName=this.appleFamilyName;
      exAuthObj.GivenName=this.appleGivenName;
      exAuthObj.ExAuthToken=this.appleToken;
      
      if(this.appleEmail!=undefined && this.appleEmail!='')
        exAuthObj.ExUserName=this.appleEmail;
    }

    this.authService.loginWithExternalToken(exAuthObj, this.DeviceOSName, this.ThisAppVersion, (data)=>{
      loading.dismiss();
      if(data==="success"){
        this.modalCtrl.dismiss();
      }
    });
  }
}
