  <ion-toolbar>
    <ion-buttons slot="end">
      <ion-icon size="large" (click)="cancel();" name="close-circle-outline"></ion-icon>
    </ion-buttons>
  </ion-toolbar>
  
<ion-content class="ion-padding ion-text-center">
    <p>Enjoy <span class="cashback-text ion-no-margin">{{CashBackTextOnly}} Cash Rebate</span> from <b>{{storeName}}</b></p>

    <ion-button href="{{url}}" target="_blank" (click)="cancel()">Continue</ion-button>
</ion-content>
   
  