import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot } from '@angular/router';
import { CommonService } from './common.service';
@Injectable({
  providedIn: 'root'
})

export class AuthGuardService implements CanActivate {
  constructor(private commonService: CommonService) {}

  canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.commonService.isAuthenticated().then((authObj)=>{
        resolve(true);
      }).catch(()=>{
        resolve(false);
      });
    });
    
  }
}
