//https://devdactic.com/cache-api-response-ionic/

import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import * as CordovaSQLiteDriver from 'localforage-cordovasqlitedriver'
 
// Expire time in seconds
const TTL = 60 * 60;
// Key to identify only cached API data
const CACHE_KEY = '_SSEMCACHE_';
 
@Injectable({
  providedIn: 'root'
})
export class CachingService {
 
  constructor(private storage: Storage) { }
 
  // Setup Ionic Storage
  async initStorage() {
    await this.storage.defineDriver(CordovaSQLiteDriver);
    await this.storage.create();
  }
 
  // Store request data
  cacheData(cacheKey:string, data){
    const validUntil = (new Date().getTime()) + TTL * 1000;
    cacheKey = `${CACHE_KEY}${cacheKey}`;
    this.storage.set(cacheKey, {validUntil, data});
  }
 
  // Try to load cached data
  async getCachedData(cacheKey:string): Promise<any>{
    const currentTime = new Date().getTime();
    cacheKey = `${CACHE_KEY}${cacheKey}`;
    
    const storedValue = await this.storage.get(cacheKey);
    
    if (!storedValue) {
      return null;
    } else if (storedValue.validUntil < currentTime) {
      await this.storage.remove(cacheKey);
      return null;
    } else {
      return storedValue.data;
    }
  }
 
  // Remove all cached data & files
  async clearCachedData() {
    await this.storage.clear().then(()=>{
    })
    // const keys = await this.storage.keys();
    
    // keys.map(async key => {
    //   if (key.startsWith(CACHE_KEY)) {
    //     await this.storage.remove(key);
    //   }
    // });
  }
 
  // Example to remove one cached URL
  async invalidateCacheEntry(cacheKey) {
    cacheKey = `${CACHE_KEY}${cacheKey}`;
    await this.storage.remove(cacheKey);
  }
}