import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { CommonService } from './common.service';
import { AlertController, LoadingController } from '@ionic/angular';

import { SERVER_URL } from '../../environments/environment';
import { AuthObject } from '../classes/profile';
import { SearchResult, Merchant, Store } from '../classes/store';
@Injectable({
  providedIn: 'root'
})
export class ProductService {

  constructor(
    private alertCtrl: AlertController,
    private loadingCtrl: LoadingController,
    private httpClient: HttpClient,
    private commonService: CommonService) { }

  public getProducts(
    pageNumber: number, 
    pageSize: number, 
    keyword: string,
    brand: string, 
    merchants: string, 
    categoryId: number): Promise<Observable<SearchResult>> {

    return this.commonService.isAuthenticated().then((authObj:AuthObject)=>{
      let Params = new HttpParams();
      Params = Params.append('keyword', keyword);
      Params = Params.append('page', pageNumber.toString());
      Params = Params.append('pageSize', pageSize.toString());
      Params = Params.append('minPrice', '');
      Params = Params.append('maxPrice', '');
      Params = Params.append('mallId', authObj.Mall.MallId);
      Params = Params.append('profileId', authObj.Profile.ProfileId);
      
      if (brand !== undefined) {
        Params = Params.append('brand', brand);
      }
  
      if (merchants !== undefined) {
        Params = Params.append('merchants', merchants);
      }
  
      if (categoryId !== undefined && categoryId !== 0) {
        Params = Params.append('categoryId', categoryId.toString());
      }
  
      return this.httpClient
        .get(SERVER_URL + '/ProductSearch/Products', {params: Params})
        .pipe(
          map(response => {
            const responseJSON = JSON.parse(JSON.stringify(response));
            if (responseJSON.ResponseData.Type === 'Success') {
              return new SearchResult(responseJSON.ResponseData.Data);
            } else {
              if (responseJSON.ResponseData.Data.StatusCode === 401) {
                throw new Error('TokenExpired');
              } else {
                throw new Error(responseJSON.ResponseData.Data.Message);
              }
            }
          })
        );
    });
    
  }

  public getProductMerchant(merchantId: number): Promise<Observable<Merchant>> {
    return this.commonService.isAuthenticated().then((authObj:AuthObject)=>{
      let Params = new HttpParams();
      Params = Params.append('mallId', authObj.Mall.MallId);
      Params = Params.append('profileId', authObj.Profile.ProfileId);
      Params = Params.append('endUserCashBack', authObj.Mall.CashBack.toString());
      Params = Params.append('merchantId', merchantId.toString());
  
      return this.httpClient
        .get(SERVER_URL + '/ProductSearch/ProductMerchant', {params: Params})
        .pipe(
          map(response => {
            const responseJSON = JSON.parse(JSON.stringify(response));
            if (responseJSON.ResponseData.Type === 'Success') {
              return new Merchant(responseJSON.ResponseData.Data);
            } else {
              throw new Error(responseJSON.ResponseData.Data.Message);
            }
          })
        );
    })
  }

  public getProductMerchants(productUniqueId: string): Observable<Merchant[]> {
    let Params = new HttpParams();
    Params = Params.append('productUniqueId', productUniqueId);

    return this.httpClient
      .get(SERVER_URL + '/ProductSearch/ProductMerchants', {params: Params})
      .pipe(
        map(response => {
          const responseJSON = JSON.parse(JSON.stringify(response));
          if (responseJSON.ResponseData.Type === 'Success') {
            return responseJSON.ResponseData.Data.map(data => new Merchant(data));
          } else {
            throw new Error(responseJSON.ResponseData.Data.Message);
          }
        })
      );
  }

  public RegisterProductSearchClick(store: Merchant): Promise<Observable<string>> {
    return this.commonService.isAuthenticated().then((authObj:AuthObject)=>{
      let Params = new HttpParams();
      Params = Params.append('mallId', authObj.Mall.MallId);
      Params = Params.append('profileId', authObj.Profile.ProfileId);
      Params = Params.append('merchantId', store.Id.toString());
      Params = Params.append('affiliateUrl', store.AffiliateUrl);

      return this.httpClient
        .post(SERVER_URL + '/ProductSearch/RegisterClick', '', {params: Params})
        .pipe(
          map(response => {
            const responseJSON = JSON.parse(JSON.stringify(response));
            if (responseJSON.ResponseData.Type === 'Success') {
              return responseJSON.ResponseData.Data.clickUrl;
            } else {
              throw new Error(responseJSON.ResponseData.Data.Message);
            }
          })
        );
    });
  }

  public async RegisterClick(store: Merchant){
    var _store=new Store();
    _store.CashBackText=store.CashBackText;
    _store.Name=store.Name;
    _store.Id=store.Id;
    
    this.commonService.RegisterClick(_store,undefined);
  }
}
