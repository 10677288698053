import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { ModalController } from '@ionic/angular';

import { SERVER_URL } from '../../environments/environment';
import { AuthObject, UserProfile, Mall, Configuration} from '../classes/profile';

import { Router } from '@angular/router';
import { JoinnowPage } from '../joinnow/joinnow.page';
import { OpenShopUrlPage } from '../openshopurl/openshopurl.page';

import { Browser } from '@capacitor/browser';
import { Preferences } from '@capacitor/preferences';
import { CachingService } from './caching.service';
import * as CryptoJS from 'crypto-js';

import { AlertController, LoadingController  } from '@ionic/angular';
import { Platform } from '@ionic/angular';
import { Capacitor } from '@capacitor/core';
import { Store } from '../classes/store';
@Injectable({
  providedIn: 'root'
})
export class CommonService {
  constructor(
    private alertCtrl:AlertController,
    private loadingCtrl: LoadingController,
    private router:Router,
    private httpClient: HttpClient,
    private modalCtrl: ModalController,
    private cachingService: CachingService,
    private platform: Platform,
  ) { }

  private encrypt(msg:string){
    var keyWords = CryptoJS.enc.Utf8.parse('V1RM3GAK');
    var ivWords = CryptoJS.enc.Utf8.parse('V1RM3GAV');
    return CryptoJS.DES.encrypt(msg, keyWords, { iv: ivWords}).toString();
  }

    public getAppConfiguration(): Promise<Configuration>{
      return this.cachingService.getCachedData('conf').then((data)=>{
        var conf:Configuration=null;
        if(data!=null){
          conf=new Configuration(data);
        }

        if(conf!=null && conf.MallId!==""){
          return conf;
        }else{
          return this.httpClient
          .get('../../assets/resources/configuration.json')
          .toPromise()
          .then(data => {
                var conf=new Configuration(data);
                this.cachingService.cacheData('conf',conf);
                return conf;
            });
        }
      });
    }

    public updateParentRefCode(conf:Configuration){
      this.cachingService.cacheData('conf',conf);
    }

    public isAuthenticated(): Promise<AuthObject> {
    return new Promise((resolve, reject) => {
      Preferences.get({ key: 'authObject' }).then(ret => {
         const authObj = JSON.parse(ret.value);
         if (authObj!=null) {
          this.httpClient.get(SERVER_URL + '/membership/VerifyToken').subscribe({
            next: async (response) => {
              const responseJSON = JSON.parse(JSON.stringify(response));
              if (responseJSON.ResponseData && responseJSON.ResponseData.Type === 'Error') {
                const httpHeaders = new HttpHeaders()
                .delete('Authorization')
                .set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');

                const params = new URLSearchParams();
                params.set('grant_type', 'refresh_token');
                params.set('refresh_token', authObj.RefreshToken);
                params.set('hashkey', this.encrypt(new Date().toISOString()));
                this.httpClient.post(SERVER_URL + '/myvir_token', params.toString(), {headers: httpHeaders})
                .pipe(
                  map(loginResponse => {
                    if (loginResponse) {
                      const loginResponseJSON = JSON.parse(JSON.stringify(loginResponse));
                      const authObject: AuthObject = new AuthObject();
                      authObject.AuthToken = loginResponseJSON.access_token;
                      authObject.Profile = new UserProfile({
                        ProfileId: loginResponseJSON.profileId,
                      });
      
                      authObject.Mall= new Mall({
                        MallId: loginResponseJSON.MallId,
                        MallName: loginResponseJSON.MallName,
                        CashBack: loginResponseJSON.cashback, 
                        CbText: loginResponseJSON.CbText,
                        LogoURL: loginResponseJSON.LogoURL,
                        MinimumEligibleAmount: loginResponseJSON.MinimumEligibleAmount,
                        PortalType: loginResponseJSON.PortalType,
                        Profile2MallId: loginResponseJSON.profile2mallid,
                        SignupBonus: loginResponseJSON.SignupBonus
                      });
                      authObject.RefreshToken = loginResponseJSON.refresh_token;
                      authObject.TokenExpireIn = loginResponseJSON.expires_in;
                      authObject.IsGuestLogin=authObj.IsGuestLogin;
                     
                      return authObject;
                    } else {
                      
                      throw new Error('Not a valid login resposne');
                    }
                  })
                ).subscribe({
                  next: async (newAuthObj)=>{
                    Preferences.set({
                      key: 'authObject',
                      value: JSON.stringify(newAuthObj)
                    }).then(()=>{
                      resolve(newAuthObj);
                    });
                  },
                  error: async (err)=>{
                    Preferences.remove({key:'authObject'}).then(async ()=>{
                      await this.autoLoginGuest();
                    });
                  }
                });

              } else {
                resolve(authObj);
              }
            },
            error: async err => {
              this.autoLoginGuest();
            }
          });
         } else {
            this.autoLoginGuest();
         }
       }).catch((ex) => {
          reject();
        });
     });
  }
  public readonly apiURL: string = SERVER_URL;
  public async autoLoginGuest(){
    var conf=await this.getAppConfiguration();

    const loading = await this.loadingCtrl.create({
      spinner: 'crescent',
      message: 'Loading...',
    });
    const params = new URLSearchParams();
    params.set('grant_type', 'password');
    params.set('username', 'guestTokeN');
    params.set('password', this.encrypt(conf.GuestPasswordHash));

    params.set('hashkey', this.encrypt(new Date().toISOString()));

    const httpHeaders = new HttpHeaders()
    .delete('Authorization')
    .set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
    await loading.present();
    this.httpClient.post(this.apiURL + '/myvir_token', params.toString(), {headers: httpHeaders})
          .pipe(
            map(loginResponse => {
              if (loginResponse) {
                const loginResponseJSON = JSON.parse(JSON.stringify(loginResponse));
                const authObject: AuthObject = new AuthObject();
                authObject.AuthToken = loginResponseJSON.access_token;
                authObject.Profile = new UserProfile({
                  ProfileId: loginResponseJSON.profileId
                });

                authObject.Mall= new Mall({
                  MallId: loginResponseJSON.MallId,
                  MallName: loginResponseJSON.MallName,
                  CashBack: loginResponseJSON.cashback, 
                  CbText: loginResponseJSON.CbText,
                  LogoURL: loginResponseJSON.LogoURL,
                  MinimumEligibleAmount: loginResponseJSON.MinimumEligibleAmount,
                  PortalType: loginResponseJSON.PortalType,
                  Profile2MallId: loginResponseJSON.profile2mallid,
                  SignupBonus: loginResponseJSON.SignupBonus
                });
                authObject.RefreshToken = loginResponseJSON.refresh_token;
                authObject.TokenExpireIn = loginResponseJSON.expires_in;
                authObject.IsGuestLogin=true;
                return authObject;
              } else {
                throw new Error('Not a valid login resposne');
              }
            })
        ).subscribe({
          next: async (authObj:AuthObject)=>{
            await Preferences.set({
              key: 'authObject',
              value: JSON.stringify(authObj)
            }).then(()=>{
              loading.dismiss();
              this.router.navigate(['']);
            });
          },
          error: async error=>{
            loading.dismiss();
            let msg = 'Unknown error';
            if (error instanceof HttpErrorResponse) {
              if ((error as HttpErrorResponse).error.error_description !== undefined) {
                msg = (error as HttpErrorResponse).error.error_description;
                
              } else {
                msg = (error as HttpErrorResponse).message;
              }
            }

            if(msg.split("|").length>1)
              msg=msg.split("|")[1].trim()
            
              let alrtBtns=[{
                text:'Ok',
                handler: ()=>{
                  this.showSigninModal(false);
                }
              }]

              const alert = await this.alertCtrl.create({
                header: '',
                message: msg,
                buttons: alrtBtns
              });
              await alert.present();
          }
        });
  }

  public async showSigninModal(isSignin:boolean){
    const modal=await this.modalCtrl.create({
      component: JoinnowPage,
      cssClass:'my-custom-modal-css',
      backdropDismiss:false,
      componentProps:{
        formName: isSignin?"signin":"signup"
      }
    });

    modal.onWillDismiss().then(async result=>{
      if(result.data!=="dismiss")
        //window.location.reload();
        window.location.assign('/');
    });

    await modal.present();
  }
  
  // Logout a user, destroy token and remove
  // every information related to a user
  async logout(): Promise<boolean> {
    return new Promise((resolve) => {
      Preferences.remove({key:'authObject'}).then(async ()=>{
        await this.autoLoginGuest();
        resolve(true);
      });
    });
  }

  public async RegisterClick(store: Store, offerId: string){
    const loading = await this.loadingCtrl.create({
      spinner: 'crescent',
      message: 'Please wait...',
    });
    await loading.present();
    await this.isAuthenticated().then(async (authObj:AuthObject)=>{
        var msg="Enjoy " + store.CashBackTextOnly + "Cash Rebate from " + store.Name;

        if(authObj.IsGuestLogin){
          msg="Your are not logged into your account. Please sign in to continue";
        }
          
        if(authObj.IsGuestLogin){
          let altButtons=[];
          altButtons.push({
            text:'Cancel',
            handler: () => {}
          });
          altButtons.push({
            text:'Sign in',
            handler:()=>{
              this.showSigninModal(true);
            }
          });

          let alert = await this.alertCtrl.create({ 
            header: "",
            message: msg,
            buttons: altButtons
          });
          loading.dismiss();
          alert.present();
        }else{
          var clickSource: string;
          if(!Capacitor.isNativePlatform()){
            clickSource = "PWA"
          }
          let Params = new HttpParams();
          Params = Params.append('mallId', authObj.Mall.MallId);
          Params = Params.append('profileId', authObj.Profile.ProfileId);
          Params = Params.append('merchantId', store.Id.toString());
          
          if(offerId!==undefined)
            Params = Params.append('offerId', offerId);

          if(clickSource!==undefined)
            Params = Params.append('clickSource', clickSource);


          this.httpClient.get(SERVER_URL + '/shop/registerClick', {params: Params})
          .pipe(
            map(response => {
              const responseJSON = JSON.parse(JSON.stringify(response));
              if (responseJSON.ResponseData.Type === 'Success') {
                return responseJSON.ResponseData.Data.RedirectUrl;
                
              } else {
                throw new Error(responseJSON.ResponseData.Data.Message);
              }
            })
          ).subscribe({
            next: async (redirectUrl)=>{
              loading.dismiss();
              if(Capacitor.isNativePlatform()){
                Browser.open({url: redirectUrl});
              }else{
                const modal=this.modalCtrl.create({
                  component: OpenShopUrlPage,
                  cssClass:'openshopurlpage-modal-css',
                  backdropDismiss:false,
                  componentProps:{
                    CashBackTextOnly: store.CashBackTextOnly,
                    storeName: store.Name,
                    url: redirectUrl
                  }
                });

                (await modal).present();
              }
            },
            error: (err)=>{
              throw err;
            }
          });
        }
      });
  }
}
